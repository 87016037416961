<template>
  <div class="hello">
    	<div class="common_header_nav">
        <el-row>
          <!-- <el-col :span="6" :offset="1">
            <el-dropdown class="switch_company">
              <el-button class="switch_company_button" type="text">
                当前幼儿园：XXXXX幼儿园<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>AAAA幼儿园</el-dropdown-item>
                <el-dropdown-item>DDDD幼儿园</el-dropdown-item>
                <el-dropdown-item>EEEE幼儿园</el-dropdown-item>
                <el-dropdown-item>FFFF幼儿园</el-dropdown-item>
                <el-dropdown-item>KKKK幼儿园</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col> -->
         
          <el-col :span="15" :offset="9">
            <div class="header_logout"><el-button type="text" @click="handleLoginOut" icon="el-icon-switch-button">退出</el-button></div>
            <div class="header_userinfo user_info">
              欢迎，<strong>{{user_name}}</strong>&nbsp;
              <el-tag v-if="login_power == 'user'" size="mini">普通用户</el-tag>
              <el-tag v-if="login_power == 'k_user'" size="mini">园所教师</el-tag>
              <el-tag v-if="login_power == 'k_admin'" size="mini">园所管理员</el-tag>
              <el-tag v-if="login_power == 'admin_user'" size="mini" type="danger">超级管理员</el-tag> 
            </div>
            
            <!-- <div class="header_avatar"><el-avatar :size="25" icon="el-icon-user-solid"></el-avatar></div> -->
            <!-- <div class="header_message" v-if="login_power == 'admin_user'"  >
              <el-badge type="danger" is-dot :hidden="showNewMessageDot">
                <i class="message_dot el-icon-chat-dot-square" @click="handleGotoMessage" ></i>
              </el-badge>
            </div> -->
            
            <!-- <el-row>
               <el-col :span="1" :offset="15">1</el-col>
              <el-col :span="3" style="padding-top:6px"><el-avatar :size="25" icon="el-icon-user-solid"></el-avatar></el-col>
              <el-col :span="21">
                <div class="user_info">
                  欢迎，<strong>{{user_name}}</strong>&nbsp;
                  <el-tag v-if="login_power == 'staff_user'"  size="mini">幼儿园</el-tag>
                  <el-tag v-if="login_power == 'supplier_user'"  size="mini">供应商</el-tag>
                  <el-tag v-if="login_power == 'admin_user'" size="mini">管理员</el-tag> 
                  <div v-if="login_power == 'supplier_user' && !!company_name" >{{company_name}}</div>
                  <div v-if="login_power == 'staff_user' && !!kindergarten_name" >{{kindergarten_name}}</div>
                  <div v-if="login_power == 'admin_user'" >总部</div>
                </div>
              </el-col>
              <el-button type="text" @click="handleLoginOut" icon="el-icon-switch-button">退出</el-button>
            </el-row> -->
          </el-col>
         
        </el-row>

        <div class="clear"></div>
					<!-- <el-avatar size="medium" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar> -->
      </div>
  </div>
</template>

<script>

import * as MN from '@/api/common'
export default {
  name: 'common-header',
  props: {
    msg: String,
    selec:String
  },
  data(){
    return{
      login_power:'',
      company_name:'',
      kindergarten_name:'',
      user_name:'',
      showNewMessageDot:true
    }
  },
  created() {
		this.handleLoginUser()
	},
  methods:{
    handleGotoMessage(){
      this.$router.push('/message');
    },
    handleLoginUser(){
      // let checkSession = sessionStorage.getItem('kindergarten_meals_session');
      // if ( !checkSession ){
      //   this.$router.push('/');
      // }
      let me = this;
      // let post={
      //   meals_session:sessionStorage.getItem('kindergarten_meals_session'),
			// 	meals_usertype:sessionStorage.getItem('kindergarten_meals_usertype')
			// }
      MN.kindergartenLoginUser().then(res => {
        me.login_power = res.u_power
        me.user_name = res.u_name
				console.log(res)
        
			})
    },
    handleLoginOut(){
      let me = this;
      sessionStorage.removeItem('kindergarten_class_session')
      sessionStorage.removeItem('kindergarten_class_usertype')
      MN.loginOut().then(res => {
				me.$router.push('/');
        return
			})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .message_dot{ font-size: 22px; box-shadow:1px 1px 3px #8fb296; line-height: 35px; border-radius: 50%; text-align: center; margin:0 0 0 0; width: 35px; height: 35px; background-color: #3f967e; cursor: pointer; }
  .header_avatar{ float: right; margin: 7px 5px 0 30px;}
  .header_message{ float:right; margin-top: 4px; color: #ffffff;  }
  .header_userinfo{ float:right; margin: 0 30px 0 0; }
  .header_logout{ float: right; margin-right: 20px; }
  .user_info{ font-size: 12px; padding: 12px 0 0 0; }
  .switch_company_button{ color: #1d821f;  }
  .switch_company{ color: #1d821f; }
  .clear{ clear: both; }
</style>
