<template>
  <div class="hello">
    	<div class="admin-logo"><img src="@/assets/ki_logo_w.png" width="80%"></div>
			<div style="width:190px">
        <el-menu
          :default-active="selec"
          class="el-menu-vertical-demo"
          background-color="#F8F8F8"
          text-color="#444444"
          active-text-color="#4B8FE2" style="width:190px">
          <el-menu-item  index="1"  @click="$router.push('/courseware')" >
            <i class="el-icon-receiving"></i>
            <span slot="title">资源中心</span>
          </el-menu-item>
          <el-menu-item v-if="login_user_power=='admin_user'"  index="2" @click="$router.push('/system')" >
            <i class="el-icon-set-up"></i>
            <span slot="title">设置中心</span>
          </el-menu-item>
          <el-menu-item v-if="login_user_power=='admin_user'"  index="3" @click="$router.push('/wechat')" >
            <i class="el-icon-chat-dot-round"></i>
            <span slot="title">微信小程序</span>
          </el-menu-item>
        </el-menu>
      </div>
  </div>
</template>

<script>
import * as MN from '@/api/common'
export default {
  name: 'common-nav',
  props: {
    msg: String,
    selec:String
  },
  data(){
    return{
      login_user_power:'',
      supplier_manage_food:'',
      supplier_manage_dish:''
    }
  },
  created() {
		this.handleLoginUser()
	},
  methods:{
    handleLoginUser(){
			let me = this;
			MN.kindergartenLoginUser().then(res => {
				me.login_user_power = res.u_power;
				return
			})
		},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0 0 0;
  
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0;
}
a {
  color: #42b983;
}
.el-menu{
  width: 200px;
}
.el-submenu{
  width: 200px;
  border-bottom:1px solid #e0e0e0;
}
.el-menu-item .disabled{
  border-bottom:1px solid #e0e0e0;
}
.el-menu-item{
  width: 200px;
  border-bottom:1px solid #e0e0e0;
}
</style>
