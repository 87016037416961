import request from '@/utils/request'
import {
  requestUrl
} from './requestUrl.js'


export function delBannerPic(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/delBannerPic',
    method: 'POST',
    data:query
  })
}


export function setNewBannerPic(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setNewBannerPic',
    method: 'POST',
    data:query
  })
}

export function resetMiniBgPic(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/resetMiniBgPic',
    method: 'POST',
    data:query
  })
}



export function setMiniBgPic(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setMiniBgPic',
    method: 'POST',
    data:query
  })
}


export function setNineBtnDataSave(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setNineBtnDataSave',
    method: 'POST',
    data:query
  })
}


export function getFirstCaseClassData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getFirstCaseClassData',
    method: 'POST',
    data:query
  })
}


export function checkLogin(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/checkLogin',
    method: 'POST',
    data:query
  })
}


export function setDelClass(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelClass',
    method: 'POST',
    data:query
  })
}



export function setAddSubClass(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setAddSubClass',
    method: 'POST',
    data:query
  })
}


export function setFirstClass(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setFirstClass',
    method: 'POST',
    data:query
  })
}

export function setSaveNewKindergarten(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewKindergarten',
    method: 'POST',
    data:query
  })
}

export function setDelKindergarten(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelKindergarten',
    method: 'POST',
    data:query
  })
}


export function getWechatMiniData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getWechatMiniData',
    method: 'POST',
    data:query
  })
}

export function getkindergartenDataList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getkindergartenDataList',
    method: 'POST',
    data:query
  })
}


export function loginOut(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/loginOut',
    method: 'POST',
    data:query
  })
}


export function setDelAdmin(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelAdmin',
    method: 'POST',
    data:query
  })
}


export function setSaveNewAdmin(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewAdmin',
    method: 'POST',
    data:query
  })
}


export function setChangeUserStatus(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeUserStatus',
    method: 'POST',
    data:query
  })
}

export function delCasePic(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/delCasePic',
    method: 'POST',
    data:query
  })
}

export function getCaseClassData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getCaseClassData',
    method: 'POST',
    data:query
  })
}

export function getkindergartenListData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getkindergartenListData',
    method: 'POST',
    data:query
  })
}


export function getCaseSetDetail(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getCaseSetDetail',
    method: 'POST',
    data:query
  })
}


export function saveCaseSet(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/saveCaseSet',
    method: 'POST',
    data:query
  })
}


export function saveCasePicUrl(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/saveCasePicUrl',
    method: 'POST',
    data:query
  })
}


export function setDeleteCase(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDeleteCase',
    method: 'POST',
    data:query
  })
}

export function getUserDataList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getUserDataList',
    method: 'POST',
    data:query
  })
}


export function searchTypeCaseData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/searchTypeCaseData',
    method: 'POST',
    data:query
  })
}

export function searchCaseData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/searchCaseData',
    method: 'POST',
    data:query
  })
}


export function getCaseClassList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getCaseClassList',
    method: 'POST',
    data:query
  })
}

export function getCaseDetail(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getCaseDetail',
    method: 'POST',
    data:query
  })
}


export function setNewUploadDataSave(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setNewUploadDataSave',
    method: 'POST',
    data:query
  })
}

export function getCoursewareList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getCoursewareList',
    method: 'POST',
    data:query
  })
}


export function kindergartenLoginUser(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/kindergartenLoginUser',
    method: 'POST',
    data:query
  })
}




