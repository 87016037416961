<template>
  <div>
    <el-container class="data_skc">
      <el-aside class="aside-style" width="200px">
        <common-nav selec="2"></common-nav>
      </el-aside>

      <el-container>
        <el-header class="common_header">
          <common-header></common-header>
        </el-header>

        <el-main>
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="园所管理" name="setkindergarten">
              <el-row :gutter="24" class="page_bar">
                <el-col :span="18">
                  <el-button
                    size="small"
                    type="success"
                    @click="showAddKindergarten"
                    icon="el-icon-plus"
                    >新建幼儿园</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                v-loading="loading"
                :data="kindergartenTableData"
                size="medium"
                stripe
                highlight-current-row
              >
                <el-table-column prop="status" label="状态" width="80">
                  <template slot-scope="scope">
                    <label v-if="scope.row.status == 1" style="color: green"
                      >正常</label
                    >
                    <label v-if="scope.row.status == 0" style="color: red"
                      >停用</label
                    >
                    <!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="name" label="联系人名" ></el-table-column> -->
                <el-table-column
                  prop="name"
                  width="400"
                  label="幼儿园名称"
                ></el-table-column>
                <el-table-column prop="tel" label="联系方式"></el-table-column>
                <el-table-column
                  prop="address"
                  label="联系地址"
                ></el-table-column>
                <el-table-column label="操作" width="220">
                  <template slot-scope="scope">
                    <el-button
                      @click="
                        handleChangeUserStatus(
                          '1',
                          'kindergarten_user',
                          scope.row.uuid
                        )
                      "
                      v-if="scope.row.status == 0"
                      type="success"
                      size="mini"
                      plain
                      >启用</el-button
                    >
                    <el-button
                      @click="
                        handleChangeUserStatus(
                          '0',
                          'kindergarten_user',
                          scope.row.uuid
                        )
                      "
                      v-if="scope.row.status == 1"
                      type="warning"
                      size="mini"
                      plain
                      >停用</el-button>

                      <el-button
                      @click="handleEditKindergarten(scope.row)"
                      type="primary"
                      size="mini"
                      plain>修改</el-button>
                      
                    <el-button
                      @click="handleDelKindergarten(scope.row.uuid)"
                      type="danger"
                      size="mini"
                      plain>删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>

            <el-tab-pane label="用户管理" name="setuser">
              <el-row :gutter="24" class="page_bar">
                <el-col :span="18">
                  <el-button
                    size="small"
                    type="success"
                    icon="el-icon-plus"
                    @click="showAddAdminUser"
                    >新建用户</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                v-loading="loading"
                :data="adminTableData"
                size="medium"
                stripe
                highlight-current-row
              >
                <el-table-column prop="status" label="状态" width="80">
                  <template slot-scope="scope">
                    <label v-if="scope.row.status == 1" style="color: green"
                      >正常</label
                    >
                    <label v-if="scope.row.status == 0" style="color: red"
                      >停用</label
                    >
                    <!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
                  </template>
                </el-table-column>
                <el-table-column prop="power" label="权限" width="100">
                  <template slot-scope="scope">
                    <label v-if="scope.row.power == 'admin_user'">超级管理员</label>
                    <label v-if="scope.row.power == 'k_admin'">管理员</label>
                    <label v-if="scope.row.power == 'k_user'">教师</label>
                    <label v-if="scope.row.power == 'user'">用户（游客）</label>
                    <!-- 'c_user'
									'd_user' -->
                    <!-- <label v-if="scope.row.status == 2" style="color:red">停用</label> -->
                    <!-- <label v-if="scope.row.status == 3" style="color:green">已下单</label> -->
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column
                  prop="kindergarten_name"
                  width="500"
                  label="所属幼儿园"
                ></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <!-- <el-table-column prop="kindergarten_name" width="300" label="幼儿园名称" ></el-table-column> -->
                <!-- <el-table-column prop="address" label="联系地址" ></el-table-column> -->
                <el-table-column label="操作" width="210">
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.uuid != '3981A9D78100A64223A043E44DD3EA64'
                      "
                    >
                      <el-button
                        v-if="scope.row.status == 0"
                        @click="
                          handleChangeUserStatus(
                            '1',
                            'admin_user',
                            scope.row.uuid
                          )
                        "
                        type="success"
                        size="mini"
                        plain
                        >启用</el-button
                      >
                      <el-button
                        v-if="scope.row.status == 1"
                        @click="
                          handleChangeUserStatus(
                            '0',
                            'admin_user',
                            scope.row.uuid
                          )
                        "
                        type="warning"
                        size="mini"
                        plain
                        >停用</el-button
                      >

                      <el-button
                      @click="handleEditAdmin(scope.row)"
                      type="primary"
                      size="mini"
                      plain>修改</el-button>

                      <el-button
                        @click="handleDelAdmin(scope.row.uuid)"
                        type="danger"
                        size="mini"
                        plain
                        >删除</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="10"
                class="page_pagination"
              >
              </el-pagination>
            </el-tab-pane>

            <el-tab-pane
              label="资源分类管理"
              name="setclass"
              style="width: 100%"
            >
              <el-row :gutter="24" class="page_bar">
                <el-col :span="18">
                  <el-button
                    size="small"
                    type="success"
                    icon="el-icon-plus"
                    @click="handleshowManageFirstClass"
                    >新建顶级分类</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                style="width: 100%"
                v-loading="loading"
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
                row-key="id"
                :data="classTableData"
                size="medium"
                stripe
                highlight-current-row
              >
                <!-- <el-table-column prop="name" label="分类名称" ></el-table-column> -->
                <el-table-column>
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                    <span v-if="scope.row.ppid == '0'">
                      <el-link
                        class="sixo_btn"
                        type="success"
                        @click="handleAddSubClass(scope.row)"
                        >添加子项</el-link
                      >
                    </span>
                    <el-link
                      class="sixo_btn"
                      type="primary"
                      @click="handleEditFirstClass(scope.row)"
                      >修改</el-link
                    >
                    <el-link
                      class="sixo_btn"
                      type="danger"
                      @click="handleDelClass(scope.row)"
                      >删除</el-link
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-main>

        <el-footer> copyright &copy; xjjgweiguang.com </el-footer>
      </el-container>
    </el-container>

    <el-dialog
      :title="UserDialogTitle"
      :visible.sync="dialogAddAdmin"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-row class="row_spac">
        <el-col :span="14">
          <el-row class="row_spac" :gutter="20">
            <el-col :span="12"
              ><span class="red_dot">*</span>姓名：<el-input
                v-model="new_admin_name"
                placeholder="请输入内容"
                size="small"
              ></el-input
            ></el-col>
            <el-col :span="12"
              >权限：
              <el-select
                v-model="select_power"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in powerData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="row_spac" :gutter="20">
            <el-col :span="12" class="risl"
              ><span class="red_dot">*</span>手机号：<el-input
                v-model="new_admin_phone"
                maxlength="11"
                placeholder="请输入内容"
                size="small"
              ></el-input
            ></el-col>
            <el-col :span="11" class="risl"
              ><span class="red_dot">*</span>登陆密码：<el-input
                max="8"
                maxlength="8"
                v-model="new_admin_userpass"
                show-password
                placeholder="请输入内容"
                size="small"
              ></el-input
            ></el-col>
          </el-row>
          <el-row class="row_spac">
            <el-col :span="24"
              >提示：手机号是唯一凭证，也会用于小程序登陆时绑定用。</el-col
            >
          </el-row>
          <el-row class="row_spac" :gutter="20" v-if="select_power != '1'">
            <el-col :span="24">
              <span class="red_dot">*</span>所属园所（可多选）：
              <el-select
                multiple
                v-model="select_kindergarten_uuid"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in kindergartenListData"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          权限说明：
          <el-image
            src="https://www.xjjgweiguang.com/xcx_res/power_chart.jpg"
          ></el-image>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAddAdmin = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveNewAdmin">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="KindergartenDialogTitle"
      :visible.sync="dialogAddKindergarten"
      width="40%"
      :close-on-click-modal="false"
    >
      <el-row class="row_spac">
        <el-col :span="24"
          ><span class="red_dot">*</span>幼儿园名称：<el-input
            v-model="new_kindergarten_name"
            placeholder="请输入内容"
            size="small"
          ></el-input
        ></el-col>
      </el-row>
      <el-row class="row_spac">
        <el-col :span="24"
          >详细地址：<el-input
            v-model="new_kindergarten_address"
            placeholder="请输入内容"
            size="small"
          ></el-input
        ></el-col>
      </el-row>
      <!-- <el-row class="row_spac">
			<el-col :span="12"><span class="red_dot">*</span>联系人姓名：<el-input v-model="new_kindergarten_people" placeholder="请输入内容" size="small"></el-input></el-col>
		</el-row> -->
      <el-row class="row_spac">
        <el-col :span="15"
          ><span class="red_dot">*</span>联系电话：<el-input
            maxlength="11"
            v-model="new_kindergarten_phone"
            placeholder="请输入内容"
            size="small"
          ></el-input
        ></el-col>
        <!-- <el-col :span="11" :offset="1" class="risl"><span class="red_dot">*</span>登陆密码：<el-input  max="8" maxlength="8" show-password v-model="new_kindergarten_userpass" placeholder="请输入内容" size="small"></el-input></el-col> -->
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAddKindergarten = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveNewKindergarten"
          >保 存</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="dialogFirstClassTitle"
      :visible.sync="showManageFirstClass"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-row class="row_spac">
        <el-col :span="24"
          ><span class="red_dot">*</span>分类名称：<el-input
            maxlength="100"
            v-model="new_first_class"
            placeholder="请输入内容"
          ></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showManageFirstClass = false">取 消</el-button>
        <el-button type="primary" @click="handleSetFirstClass">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加子项"
      :visible.sync="showManageAddClass"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-row class="row_spac">
        <el-col :span="24"
          ><span class="red_dot">*</span>分类名称：<el-input
            maxlength="100"
            v-model="new_sub_class"
            placeholder="请输入内容"
          ></el-input
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showManageAddClass = false">取 消</el-button>
        <el-button type="primary" @click="handleSetAddSubClass"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from "@/api/common";

export default {
  name: "crm",
  props: ["selec"],
  data() {
    return {
      UserDialogTitle:"",
      edit_user_uuid:"",
      KindergartenDialogTitle:"",
      edit_kindergarten_uuid:"",
      nowid: "",
      subpid: "",
      subppid: "",
      new_sub_class: "",
      showManageAddClass: false,
      editFirstClassID: "",
      isFirstEdit: 0,
      dialogFirstClassTitle: "",
      new_first_class: "",
      showManageFirstClass: false,
      kindergartenListData: [],
      classTableData: [],
      new_type: "",
      select_power: "",
      select_kindergarten_uuid: [],
      powerData: [
        { value: 1, label: "超级管理员" },
        { value: 2, label: "管理员" },
        { value: 3, label: "教师" },
        { value: 4, label: "用户（游客）" },
      ],
      dialogAddType: false,
      dialogAddTypeTitle: "",
      new_type_name: "",
      dishTypeTableData: [],
      foodTypeTableData: [],
      foodWeightTableData: [],
      new_kindergarten_name: "",
      new_kindergarten_address: "",
      new_kindergarten_people: "",
      new_kindergarten_phone: "",
      new_kindergarten_userpass: "",

      new_supplier_company: "",
      new_supplier_address: "",
      new_supplier_name: "",
      new_supplier_phone: "",
      new_supplier_userpass: "",
      new_supplier_manage_dish: true,
      new_supplier_manage_food: true,

      new_admin_phone: "",
      new_admin_name: "",
      new_admin_userpass: "",

      activeTab: "setkindergarten",
      searchValue: "",
      loading: true,
      boxshow: false,
      curTipsID: "",
      kindergartenTableData: [],
      supplierTableData: [],
      adminTableData: [],
      dialogAddKindergarten: false,
      dialogAddSupplier: false,
      dialogAddAdmin: false,
    };
  },
  components: {
    CommonNav,
    CommonHeader,
  },

  created() {
    this.initData("setkindergarten");
  },

  mounted() {
    this.initData("setkindergarten");
  },

  methods: {
    handleEditAdmin(rowdata){
      console.log(rowdata)
      this.UserDialogTitle = "修改管理员和用户";
      this.edit_user_uuid = rowdata.uuid;

      this.new_admin_phone = rowdata.phone;
      this.new_admin_name = rowdata.name;
      this.new_admin_userpass = rowdata.userpass;

      if ( rowdata.power == 'admin_user' ){
        this.select_power = 1;
      }else if ( rowdata.power == 'k_admin' ){
        this.select_power = 2;
      }else if ( rowdata.power == 'k_user' ){
        this.select_power = 3;
      }else{
        this.select_power = 4;
      }
      
      let me = this;
      me.kindergartenListData=[];
      MN.getkindergartenListData().then((res) => {
        me.kindergartenListData = res;
        me.select_kindergarten_uuid = rowdata.kindergarten_uuid.split(",");
      });
      
      this.dialogAddAdmin = true;

    },
    handleEditKindergarten(rowdata){
      console.log("rowdata",rowdata);
      this.KindergartenDialogTitle="修改幼儿园信息";
      this.edit_kindergarten_uuid=rowdata.uuid;

      this.new_kindergarten_name = rowdata.name;
      this.new_kindergarten_address = rowdata.address;
      this.new_kindergarten_phone = rowdata.tel;

      this.dialogAddKindergarten = true;
    },
    handleDelClass(rowdata) {
      console.log("class_id", rowdata.id);
      console.log("pid", rowdata.pid);
      console.log("ppid", rowdata.ppid);
      let me = this;
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let post = {
            class_id: rowdata.id,
            pid: rowdata.pid,
            ppid: rowdata.ppid,
          };
          MN.setDelClass(post).then((res) => {
            if (res == "error_has_case") {
              me.$message({
                type: "error",
                message: "此分类下有案例不能删除",
              });
              return;
            }

            if (res == "error_has_sub") {
              me.$message({
                type: "error",
                message: "有子项分类不能删除",
              });
              return;
            }

            if (res == "del_ok") {
              me.$message({
                type: "success",
                message: "操作成功!",
              });
              me.initData("setclass");
            }
          });
        })
        .catch(() => {
          // me.$message({
          // 	type: 'info',
          // 	message: '已取消'
          // });
        });
    },
    handleEditFirstClass(rowdata) {
      this.isFirstEdit = 1;
      this.editFirstClassID = rowdata.id;
      this.new_first_class = rowdata.name;
      this.dialogFirstClassTitle = "修改顶级分类";
      this.showManageFirstClass = true;
    },
    handleAddSubClass(rowdata) {
      console.log(rowdata);
      this.nowid = rowdata.id;
      this.subpid = rowdata.pid;
      this.subppid = rowdata.ppid;
      this.showManageAddClass = true;
    },
    handleSetAddSubClass() {
      let me = this;
      console.log("nid", this.nowid);
      console.log("pid", this.subpid);
      console.log("ppid", this.subppid);
      if (!me.new_sub_class) {
        this.$message.error("请输入名字");
        return;
      }
      let post = {
        nid: me.nowid,
        pid: me.subpid,
        ppid: me.subppid,
        class_name: me.new_sub_class,
      };
      MN.setAddSubClass(post).then((res) => {
        if (res == "set_ok") {
          me.showManageAddClass = false;
          me.initData("setclass");
        }
      });
    },
    handleSetFirstClass() {
      let me = this;
      if (!me.new_first_class) {
        this.$message.error("请输入名字");
        return;
      }
      let post = {
        is_edit: me.isFirstEdit,
        class_id: me.editFirstClassID,
        class_name: me.new_first_class,
      };
      MN.setFirstClass(post).then((res) => {
        if (res == "set_ok") {
          me.showManageFirstClass = false;
          me.initData("setclass");
        }
      });
    },
    handleshowManageFirstClass() {
      this.isFirstEdit = 0;
      this.dialogFirstClassTitle = "新建顶级分类";
      this.showManageFirstClass = true;
    },
    handleSaveNewKindergarten() {
      if (!this.new_kindergarten_name) {
        this.$message.error("请输入公司名称");
        return;
      }
      // if( !this.new_kindergarten_people ){
      // 	this.$message.error('请输入联系人');
      // 	return
      // }
      if (!this.new_kindergarten_phone) {
        this.$message.error("请输入正确手机号");
        return;
      }

      let me = this;
      this.loading = true;
      let post = {
        kindergarten_uuid:me.edit_kindergarten_uuid,
        kindergarten_name: me.new_kindergarten_name,
        kindergarten_address: me.new_kindergarten_address,
        // kindergarten_people:me.new_kindergarten_people,
        kindergarten_phone: me.new_kindergarten_phone,
      };
      MN.setSaveNewKindergarten(post).then((res) => {
        me.loading = false;

        if (res == "add_ok") {
          me.dialogAddKindergarten = false;
          me.initData("setkindergarten");
        }
      });
    },
    showAddKindergarten() {
      this.new_kindergarten_name = "";
      this.new_kindergarten_address = "";
      this.new_kindergarten_people = "";
      this.new_kindergarten_phone = "";
      this.new_kindergarten_userpass = "";
      this.KindergartenDialogTitle="新建幼儿园";
      this.edit_kindergarten_uuid = "";
      this.dialogAddKindergarten = true;
    },
    handleDelAdmin(uuid) {
      let me = this;
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let post = {
            uuid: uuid,
          };
          MN.setDelAdmin(post).then((res) => {
            if (res == "only_one") {
              me.$message({
                type: "info",
                message: "唯一的管理员账号不能删除",
              });
              return;
            }

            if (res == "set_ok") {
              me.$message({
                type: "success",
                message: "操作成功!",
              });
              me.initData("setuser");
            }
          });
        })
        .catch(() => {
          me.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleDelKindergarten(uuid) {
      let me = this;
      this.$confirm("删除此幼儿园吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let post = {
            k_uuid: uuid,
          };
          MN.setDelKindergarten(post).then((res) => {
            me.$message({
              type: "success",
              message: "操作成功!",
            });
            me.initData("setkindergarten");
          });
        })
        .catch(() => {
          me.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleChangeUserStatus(new_status, u_type, uuid) {
      let show_label = "";
      let me = this;
      if (new_status == "1") {
        show_label = "确认启用吗？";
      } else {
        show_label = "确认停用吗？";
      }
      this.$confirm(show_label, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let post = {
            u_type: u_type,
            u_uuid: uuid,
            u_status: new_status,
          };
          MN.setChangeUserStatus(post).then((res) => {
            me.$message({
              type: "success",
              message: "操作成功!",
            });

            if (u_type == "admin_user") {
              me.initData("setuser");
            }

            if (u_type == "kindergarten_user") {
              me.initData("setkindergarten");
            }
          });
        })
        .catch(() => {
          me.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSaveNewAdmin() {
      if (!this.new_admin_phone) {
        this.$message.error("请输入正确手机号");
        return;
      }
      if (!this.new_admin_name) {
        this.$message.error("请输入联系人姓名");
        return;
      }
      if (!this.new_admin_userpass) {
        this.$message.error("请输入密码");
        return;
      }
      if (this.select_kindergarten_uuid.length == 0) {
        this.$message.error("请选择所属园所");
        return;
      }
      if (!this.select_power) {
        this.$message.error("请选择管理员");
        return;
      }

      let me = this;
      this.loading = true;
      let post = {
        edit_user_uuid:me.edit_user_uuid,
        admin_phone: me.new_admin_phone,
        admin_name: me.new_admin_name,
        admin_userpass: me.new_admin_userpass,
        user_type: me.select_power,
        kindergarten_uuid: me.select_kindergarten_uuid.toString(),
      };
      
      MN.setSaveNewAdmin(post).then((res) => {
        me.loading = false;
        if (res == "hasUser") {
          this.$message.error("已有此管理员账号了");
          return;
        }
        if (res == "add_ok") {
          me.dialogAddAdmin = false;
          me.initData("setuser");
        }
      });
    },
    showAddAdminUser() {

      this.UserDialogTitle="新建管理员和用户";
      this.edit_user_uuid="";

      this.new_admin_phone = "";
      this.new_admin_name = "";
      this.new_admin_userpass = "";
      this.select_power = "";
      this.dialogAddAdmin = true;
      let me = this;
      MN.getkindergartenListData().then((res) => {
        me.kindergartenListData = res;
      });
    },
    handleTabClick() {
      console.log(this.activeTab);
      this.initData(this.activeTab);
    },
    initData(activeTab) {
      let me = this;
      this.loading = true;

      if (activeTab == "setkindergarten") {
        MN.getkindergartenDataList().then((res) => {
          me.kindergartenTableData = res;
          me.loading = false;
        });
      }

      if (activeTab == "setuser") {
        MN.getUserDataList().then((res) => {
          me.adminTableData = res.list;
          me.loading = false;
        });
      }

      if (activeTab == "setclass") {
        MN.getCaseClassData().then((res) => {
          me.classTableData = res;
          me.loading = false;
        });
      }
    },
    handlePSTableDetail(rowdata) {
      this.$router.push("/topics/psdetail?uuid=" + rowdata.uuid);
    },
    handleTableDetail(rowdata) {
      this.$router.push("/topics/detail?uuid=" + rowdata.uuid);
      //this.$router.push({name: 'topicsdetail', params: {uuid: rowdata.uuid}})
    },
  },
};
</script>

<style scoped>
.sixo_btn {
  margin-left: 10px;
}
.sxia_btn {
  padding-right: 5px;
}
.fisk {
  padding-left: 20px;
}
.custom-tree-node {
  width: 100%;
  margin: 6px 0 5px 0;
}
.treepanel {
  font-size: 14px;
  padding: 8px;
  background-color: #fafafa;
}

.row_spac {
  margin-bottom: 20px;
}

.risl {
  color: #e23737;
}

.red_dot {
  color: #e23737;
  font-weight: bold;
}

/* ::v-deep .el-card__body, .el-main{
	padding: 0;
} */
</style>
