import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '../views/loginView.vue'
import CoursewareView from '../views/courseware/index.vue'
import CoursewareDetail from '../views/courseware/detail.vue'
import SystemView from '../views/system/index.vue'
import SystemDetail from '../views/system/detail.vue'
import WechatView from '../views/wechat/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: loginView
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: WechatView
  },
  {
    path: '/courseware',
    name: 'courseware',
    component: CoursewareView
  },
  {
    path: '/courseware/detail',
    name: 'coursewaredetail',
    component: CoursewareDetail
  },
  {
    path: '/system',
    name: 'system',
    component: SystemView
  },
  {
    path: '/system/detail',
    name: 'systemdetail',
    component: SystemDetail
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
